import $ from './utils/$'
import $$ from "./utils/$$";

document.addEventListener('click', e =>
{
    const btn = e.target.closest('[aria-controls][data-toggle=hidden]')
    if (btn)
    {
        const controls = btn.getAttribute('aria-controls')
        const target = $('#' + controls)
        console.log({btn, target})
        if (target)
        {
            e.preventDefault()

            target.classList.toggle('hidden')
            const hidden = target.classList.contains('hidden')

            $$('[aria-controls="' + controls + '"]')
                .forEach(toggle => toggle.setAttribute('aria-expanded', hidden ? 'false' : 'true'))
        }
    }
})
