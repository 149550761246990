import $ from './utils/$'
import ready from "./utils/ready";

const html = $('html').classList
html.remove('no-js')
html.add('js')

// if (window.speechSynthesis)
// {
//     html.add('tts')
// }

let allowSavingHighContrast = true

document.addEventListener('click', e =>
{
    let target = e.target.closest('[data-toggle=bigtext]')
    if (target)
    {
        html.toggle('bigtext')
        localStorage.bigtext = html.contains('bigtext')
    }

    target = e.target.closest('[data-toggle=highcontrast]')
    if (target)
    {
        html.toggle('highcontrast')
        if (allowSavingHighContrast)
        {
            localStorage.highcontrast = html.contains('highcontrast')
        } else
        {
            delete localStorage.highcontrast
        }
    }
})

try
{
    const noContrastPref = window.matchMedia('(prefers-contrast: no-preference)')
    const noContrastPrefUpdate = () =>
    {
        allowSavingHighContrast = noContrastPref.matches
        if (!noContrastPref.matches)
        {
            delete localStorage.highcontrast
        }
    }
    noContrastPrefUpdate()
    noContrastPref.addEventListener('change', noContrastPrefUpdate)

    const prefersMoreContrast = window.matchMedia('(prefers-contrast: more)')
    const prefersMoreContrastUpdate = () =>
    {
        console.log('prefers more contrast', prefersMoreContrast.matches)
        html.toggle('highcontrast', prefersMoreContrast.matches)
    }
    prefersMoreContrastUpdate()
    prefersMoreContrast.addEventListener('change', prefersMoreContrastUpdate)
} catch (e)
{
    console.error('matchMedia error', e);
}

ready(() =>
{
    const bigtext = localStorage.bigtext
    if (bigtext === 'true')
    {
        html.add('bigtext')
    }
    const highcontrast = localStorage.highcontrast
    if (highcontrast === 'true')
    {
        html.add('highcontrast')
    }
})