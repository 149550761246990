import $ from './utils/$'

// https://stackoverflow.com/questions/19985306/get-the-innertext-of-an-element-but-exclude-hidden-children
window.skipSpeechSynthesisSelectors = [
    'style', 'script', 'template', '[aria-hidden=true]',
    '.screenreader-skip', '.sr-hidden',
    'a.skip-to-content-link', '#page > header',
    '#wpadminbar'
];

const tts = window.speechSynthesis
const SpeechSynthesisUtterance = window.SpeechSynthesisUtterance

console.log('tts', {tts, SpeechSynthesisUtterance})

const html = $('html').classList

if (tts)
{
    html.add('tts')

    document.addEventListener('click', e =>
    {
        const btn = e.target.closest('[data-toggle=tts]')
        if (btn)
        {
            if (tts.speaking)
            {
                tts.cancel()
                return
            }
            const body = $('body')

            let utterances = getSpeechSynthesisUtterancesFromNode(body)
            // console.log({utterances})
            utterances.forEach(utt =>
            {
                tts.speak(utt)
            })
        }
    })

    updateActive()
}


function updateActive()
{
    html.toggle('tts-active', tts.speaking || tts.pending)
    requestAnimationFrame(updateActive)
}

function getLangFromNode(node)
{
    let lang = node.getAttribute ? node.getAttribute('lang') : null
    if (lang) return lang
    if (node.parentNode) return getLangFromNode(node.parentNode)
    return null
}

function getNormalizedTextContent(text)
{
    return text.replace(/\n[ \t]+/, '\n').replace(/\n{2,}/, '\n').trim()
}

function getSpeechSynthesisUtterancesFromNode(node)
{
    const lang = getLangFromNode(node)
    if (node.nodeType === Node.TEXT_NODE)
    {
        const text = getNormalizedTextContent(node.textContent)
        if (text === '')
        {
            // console.debug('node is a text node with empty text', {node})
            return []
        }
        // console.debug('node is a text node', {node, text})
        const utt = new SpeechSynthesisUtterance(text)
        utt.lang = lang
        utt.node = node
        return [utt]
    }
    if (node.nodeType !== Node.ELEMENT_NODE)
    {
        // console.debug('node is not an element node', {node})
        return []
    }
    let i, selector
    for (i = 0; i < window.skipSpeechSynthesisSelectors.length; i++)
    {
        selector = window.skipSpeechSynthesisSelectors[i]
        if (node.matches(selector))
        {
            // console.debug('node matches selector for invisible text', {node, selector})
            return [];
        }
    }

    if (node.matches('[aria-label]'))
    {
        const text = getNormalizedTextContent(node.getAttribute('aria-label'))
        if (text === '')
        {
            return []
        }

        const utt = new SpeechSynthesisUtterance(text)
        utt.lang = lang
        utt.node = node
        return [utt]
    }

    if (node.matches('iframe[title]'))
    {
        // console.debug('node is an iframe with title', {node})
        const text = getNormalizedTextContent(node.getAttribute('title'))
        if (text === '')
        {
            return []
        }

        const utt1 = new SpeechSynthesisUtterance('inline frame:')
        utt1.lang = 'en-US'

        const utt2 = new SpeechSynthesisUtterance(text)
        utt2.lang = lang
        utt2.node = node
        return [utt1, utt2]
    }

    if (node.matches('img[alt]'))
    {
        const text = getNormalizedTextContent(node.getAttribute('alt'))
        if (text === '')
        {
            return []
        }

        const utt1 = new SpeechSynthesisUtterance('afbeelding:')
        utt1.lang = 'nl-NL'

        const utt2 = new SpeechSynthesisUtterance(text)
        utt2.lang = lang
        utt2.node = node
        return [utt1, utt2]
    }

    let style = getComputedStyle(node)
    if (style && style.display === 'none')
    {
        // console.debug('node display=none', {node, style})
        return []
    }

    let strong = node.matches('strong')

    let utterances = []
    for (i = 0; i < node.childNodes.length; i++)
    {
        let childNode = node.childNodes[i]
        let childUtterances = getSpeechSynthesisUtterancesFromNode(childNode)
        if (strong && childUtterances.length)
        {
            childUtterances.forEach(utt => utt.pitch = 1.5)
        }
        utterances = utterances.concat(childUtterances)
    }

    return utterances
}
